/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { createContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
// import Data from "../Data";

export const Context = createContext();

const ContextProvider = ({ children }) => {
    const [prd, setProductId] = useState("");
    const [cartItems, setCartItems] = useState({});
    const [productMessage, setProductMessage] = useState("loading");
    const [loader, setLoader] = useState(false);
    const [cart, setCart] = useState([]);
    const [quantity, setQuantity] = useState(0);
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [loggedIn, setLoggedIn] = useState(false);
    const [userType, setUserType] = useState("");
    const [storeDetails, setStoreDetails] = useState("");
    const [totalPrice, setTotalPrice] = useState(0);
    const token = localStorage.getItem("authToken");
    const [setupCompleted, setSetupCompleted] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [modalOptions, setModalOptions] = useState({
        isOpen: false,
        handleSuccess: () => null,
        message: "Modal Message"
    });
    // const { unauntheticated } = useAuth();
    const radius = 1000;
    const header = {
        "Content-Type": "application/json",
        Accept: "application/vnd.api+json",
        Authorization: `Bearer ${token}`,
    };

    const setupChange = (dt) => setSetupCompleted(dt);

    const userProfile = (value = "", logout = false) => {
        if (value) {
            const user = JSON.stringify(value);
            localStorage.setItem("user", user);
            setUserType(value);
            return;
        }

        if (!value) {
            const getUser = localStorage.getItem("user");
            if (getUser) setUserType(JSON.parse(getUser));
            else setUserType("");
            return;
        }

        if (logout) {
            localStorage.removeItem("user");
            setUserType("");
        }
    };

    const getMerchant = async () => {
        if (!storeDetails) {
            const url =
                "https://getquickshop.online/api/v1/user/merchants/stores";
            const result = await axios.get(url, { headers: header });
            setStoreDetails(result.data.data);
            return;
        }
    };

    const togglePagination = async (page) => {
        await getAllProducts(page, selectedCategory);
    };

    const updateCurrentPage = (newPage) => {
        setCurrentPage(newPage);
    };

    const getTotalPrice = (cart) => {
        let total = cart
            ? cart.reduce(
                  (a, b) => parseFloat(a) + parseFloat(b.total_amount),
                  0
              )
            : 0;
        setTotalPrice(parseFloat(total));
    };

    const isLoggedIn = (value = "") => {
        const token = value || localStorage.getItem("authToken");
        if (token) setLoggedIn(true);
        else setLoggedIn(false);
    };

    const logout = async () => {
        const url = "https://getquickshop.online/api/v1/user/logout";
        setLoggedIn(false);
        try {
            await axios.post(
                url,
                {},
                {
                    headers: {
                        Accept: "application/vnd.api+json",
                        "Content-Type": "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        } catch (err) {
        } finally {
            localStorage.removeItem("user");
            localStorage.removeItem("authToken");
            localStorage.removeItem("businessType");
            localStorage.removeItem("firstName");
            setLoggedIn(false);
            return "completed";
        }
    };

    const getAllProducts = async (page, category) => {
        let url = "";
        if (!category)
            url = `https://getquickshop.online/api/v1/user/buyer/products/all?latitude=${latitude}&longitude=${longitude}&radius=${radius}&page=${page}`;
        else {
            setSelectedCategory(category);
            url = `https://getquickshop.online/api/v1/user/buyer/categories/${category}/products?page=${page}`;
        }

        try {
            setLoader(true);
            setProductMessage("Loading...");
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/vnd.api+json",
                    "Content-Type": "application/vnd.api+json",
                },
            });
            const data = await response.json();
            setProductMessage("");
            if (data.data) {
                if (data.data.length < 1)
                    setProductMessage("No product available for this category");
                else {
                    if (category) setProducts(data);
                    else setProducts(data.data);
                }
            } else toast.warn("error loading products");
        } catch (error) {
            setProductMessage("Can't load product!, try again!");
            console.log(error);
        } finally {
            setLoader(false);
        }
    };

    const getCartItem = async (reload = false) => {
        if (!token) return;
        try {
            const result = await axios.get(
                "https://getquickshop.online/api/v1/user/buyer/cart",
                {
                    headers: header,
                }
            );
            const data = result.data.data;
            setQuantity(data.length);
            setCart(data);
            getTotalPrice(data);
            localStorage.setItem("cart", JSON.stringify(data));
        } catch (err) {
            const message = err.response.data.message;
            if (message === "Unauthenticated.") {
                localStorage.removeItem("user");
                localStorage.removeItem("authToken");
                localStorage.removeItem("businessType");
                localStorage.removeItem("firstName");
                setLoggedIn(false);
            }
            // unauntheticated(err.respo)
        }
    };

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
        });
        getAllProducts();
        return () => {
            getAllProducts();
        };
    }, []);

    useEffect(() => {
        isLoggedIn();
        userProfile();
        getCartItem();
        if (!loggedIn) setCart([]);
    }, [loggedIn]);

    const contextValue = {
        getAllProducts,
        setupCompleted,
        loader,
        productMessage,
        prd,
        setProductId,
        setProducts,
        setupChange,
        getCartItem,
        cart,
        togglePagination,
        getMerchant,
        storeDetails,
        userProfile,
        logout,
        isLoggedIn,
        userType,
        setLoggedIn,
        loggedIn,
        products,
        currentPage,
        updateCurrentPage,
        cartItems,
        quantity,
        totalPrice,
        token,
        header,
        selectedCategory,
        setSelectedCategory,
        modalOptions, 
        setModalOptions
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export default ContextProvider;
