import React, { useContext, useState } from "react";
import { Context } from "../Context/Context";

const Modal = () => {
  const { modalOptions, setModalOptions } = useContext(Context);
  const [loading, setIsLoading] = useState(false);

  const handleModalClose = () =>
    setModalOptions({ ...modalOptions, isOpen: false });

  const handleModalContinue = async () => {
    try {
        setIsLoading(true);
        await modalOptions?.handleSuccess();
        setIsLoading(false);
        setModalOptions({ isOpen: false });
    }catch{
        setModalOptions({ message: "Unable to remove Item from cart. Please try again" });
    }
  };

  return (
    <div
      className={`fixed flex items-center justify-center top-0 left-0 bottom-0 right-0 insex-x-0 bg-black/25 w-screen h-screen p-4 ${
        !modalOptions?.isOpen && "hidden"
      }`}
    >
      <div className="w-full md:max-w-[60%] bg-white rounded-md shadow-sm p-4 space-y-4">
        <div className="px-4 py-2 text-lg font-semibold">
          <h2>Important!</h2>
        </div>
        <div className="px-4 py-6">
          <h3 className="display-4 text-xl">{modalOptions?.message}</h3>
        </div>
        <div className="flex gap-4 items-center justify-end w-full border-t border-t-skyBlue px-4 py-2">
          <button
            className="btn btn-outline-secondary"
            onClick={handleModalClose}
          >
            Close
          </button>
          <button
            disabled={loading}
            className="btn bg-gradient-to-r from-red to-orange text-white disabled:opacity-15 disabled:cursor-not-allowed"
            onClick={handleModalContinue}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

/**
 * <CModal
    //   container={() => document.querySelector("#modal-root-container")}
      backdrop="static"
      visible={modalOptions.isOpen}
      onClose={() => setModalOptions({ ...modalOptions, isOpen: false })}
      aria-labelledby="StaticBackdropExampleLabel"
    >
      <CModalHeader>
        <CModalTitle id="StaticBackdropExampleLabel">Important!</CModalTitle>
      </CModalHeader>
      <CModalBody>{modalOptions.message}</CModalBody>
      <CModalFooter>
        <CButton
          color="secondary"
          onClick={() => setModalOptions({ ...modalOptions, isOpen: false })}
        >
          Close
        </CButton>
        <CButton onClick={modalOptions.handleSuccess} color="primary">
          Continue
        </CButton>
      </CModalFooter>
    </CModal>
 */

export default Modal;
