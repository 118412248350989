import React from "react";
import logo from "../Images/logo.png";
import { FaLinkedin, FaTiktok } from "react-icons/fa";
import { IoLogoTwitter } from "react-icons/io5";
import { RiInstagramFill } from "react-icons/ri";
import { IoLocationSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";

const Footer = () => {
    const socials = [
        { icon: <FaLinkedin />, link: "https://www.linkedin.com/company/quickshop-africa/" },
        { icon: <IoLogoTwitter />, link: "https://x.com/QuickshopAfrica" },
        { icon: <RiInstagramFill />, link: "https://www.instagram.com/quickshopafrica" },
        { icon: <FaTiktok />, link: "https://www.tiktok.com/@quickshopafrica" },
    ];

    return (
        <div className="py-[80px] px-[20px] md:px-0">
            <div className="flex justify-center flex-col md:gap-[60px] gap-[30px]">
                <img src={logo} alt="" className="w-[200px] mx-auto" />
                <div>
                    <div className="text-center flex flex-col md:flex-row items-center justify-center md:gap-[12px]">
                        <MdEmail className="text-red" />
                        <span>helpdesk@getquickshop.com</span>
                    </div>
                    <div className="text-center flex-col md:flex-row flex items-center justify-center md:gap-[12px] mt-[10px] mdd:mt-[20px]">
                        <IoLocationSharp className="text-red" />
                        <span>
                            plot 162A, independence Layout, Enugu, Enugu State.
                        </span>
                    </div>
                </div>
                <div className="social flex justify-center items-center md:gap-[50px] gap-[20px]">
                    {socials.map((e) => (
                        <a
                            href={e.link}
                            key={e.link}
                            target="_blank"
                            rel="noreferrer"
                            className="p-[12px] rounded-full bg-[#FFF0EA] block text-red md:text-[24px] tex-[14px]"
                        >
                            {e.icon}
                        </a>
                    ))}
                </div>
                <small className="block text-center">
                    copyright &copy; Quickshop {new Date().getFullYear()}. All
                    rights reserved
                </small>
            </div>

            <p className="text-center pt-10"></p>
        </div>
    );
};

export default Footer;
