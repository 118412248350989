/* eslint-disable eqeqeq */
import React, { useContext, useState } from "react";
import { RxSlash } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
// import { HiMiniTrash } from "react-icons/hi2";
import Footer from "../Footer/Footer";
// import { FaPlus, FaMinus } from "react-icons/fa6";
import { Context } from "../Context/Context";
import EmptyCart from "../EmptyCart/EmptyCart";
import CartCard from "../CartCard";
import { toast } from "react-toastify";
import Navbar from "../Navbar/Navbar";
import axios from "axios";
import useCategory from "../AllProduct/useCategory";
import Modal from "../Modal/Modal";

const Cart = () => {
    const { totalPrice, getCartItem, cart, header,modalOptions, setModalOptions } = useContext(Context);
    const [selectedStore, setSelectedStore] = useState();
    const { categories, getCategories } = useCategory();
    const navigate = useNavigate();
    const handleCheckout = () => {
        const category = categories;
        if (!selectedStore) {
            toast.error("Please select a store for checkout.");
            return;
        }

        const item = [];
        const toProcess = cart.find((a) => a.merchant_id == selectedStore);
        for (let i of toProcess.relationship) {
            const cat = category.find(
                (e) => e.cat_name == i.selected_product.product_category
            );
            const returnData = {
                product_id: i.selected_product.id,
                product_name: i.selected_product.product_name,
                product_description: i.selected_product.product_name,
                quantity_sold: i.cart.quantity,
                selling_price: (
                    parseFloat(i.cart.total_amount) / parseInt(i.cart.quantity)
                ).toString(),
                category_id: cat.id.toString(),
                product_images: [i.selected_product.product_image],
            };
            item.push(returnData);
        }
        navigate("/checkout", {
            state: {
                items: item,
                merchant_id: selectedStore,
            },
        });
    };

    const updateCart = async (cart_id, action, product_id) => {
        try {
            const url = `https://getquickshop.online/api/v1/user/buyer/cart/${cart_id}`;
            await axios.patch(url, { product_id, action }, { headers: header });
            getCartItem();
        } catch (error) {
            toast.warn(error.response.data.message);
        }
    };

    const deleteCart = async (cartId) => {
        const url = `https://getquickshop.online/api/v1/user/buyer/cart/${cartId}`;
        const result = await axios.delete(url, { headers: header });
        if (result.data.message === "Cart deleted successfully")
            getCartItem(true);
    };

    useState(() => {
        getCategories();
        getCartItem();
        return () => {
            getCategories();
            getCartItem();
        };
    }, []);

    return (
        <div className="">
            <Navbar />
            <div className="bg-grey py-[100px] md:px-[100px] md:py-[40px]  px-[20px]">
                <div>
                    <div className="flex">
                        <Link to="/">Home</Link>
                        <div className="flex items-center">
                            <span className="inline-block">
                                <RxSlash />
                            </span>
                            <div className="text-red inline-block">
                                <Link to="/Cart">Cart</Link>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <h2 className="py-4 text-xl fw-bold">Cart</h2>
                    </div>
                </div>
                <div>
                    {!Object.keys(cart).length ? (
                        <EmptyCart />
                    ) : (
                        <>
        <Modal />

                        <div className="grid md:grid-cols-3 md:py-[40px] py-[20px] md:gap-[32px] gap-[16px]">                            
                            <div className="md:col-span-2 shrink-0">
                                {cart.map((store, index) => (
                                    <div
                                        key={store.merchant_id}
                                        className="rounded-[8px] bg-white border border-[#EBEDEF] mb-[20px]"
                                    >
                                        <label
                                            role="button"
                                            htmlFor={`store-${index}`}
                                            className="flex md:p-[20px] p-[12px] gap-[15px] py-[15px] border-b border-b-[#EBEDEF] items-center"
                                        >
                                            <input
                                                type="checkbox"
                                                name="store-checkout"
                                                id={`store-${index}`}
                                                className="checked:bg-[#F90013]"
                                                onChange={() => {
                                                    setSelectedStore(
                                                        store.merchant_id
                                                    );
                                                }}
                                            />
                                            {store.store.business_name}
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M6.59162 12.6314C6.39017 12.4425 6.37996 12.1261 6.56883 11.9246L10.2482 7.99993L6.56883 4.07524C6.37996 3.87378 6.39017 3.55736 6.59162 3.3685C6.79308 3.17963 7.1095 3.18984 7.29836 3.3913L11.2984 7.65796C11.4787 7.85029 11.4787 8.14957 11.2984 8.3419L7.29836 12.6086C7.1095 12.81 6.79308 12.8202 6.59162 12.6314Z"
                                                    fill="#8C8D8E"
                                                />
                                            </svg>
                                        </label>
                                        <div className="md:p-[20px] p-[12px]">
                                            {store.relationship.map(
                                                (item, i) => (
                                                    <div key={i}>
                                                        <CartCard
                                                            item={
                                                                item.selected_product
                                                            }
                                                            cartItems={
                                                                item.cart
                                                            }
                                                            removeFromCart={() =>setModalOptions({
                                                                ...modalOptions,
                                                                isOpen: true,
                                                                message: "This item will be removed from your cart forever. Continue?",
                                                                handleSuccess(){
                                                                    return deleteCart(item.cart.id)
                                                                }
                                                            })
                                                            }
                                                            handleIncrement={() =>
                                                                updateCart(
                                                                    item.cart
                                                                        .id,
                                                                    "1",
                                                                    item
                                                                        .selected_product
                                                                        .id
                                                                )
                                                            }
                                                            handleDecrement={() =>
                                                                updateCart(
                                                                    item.cart
                                                                        .id,
                                                                    "0",
                                                                    item
                                                                        .selected_product
                                                                        .id
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div>
                                <div className="bg-white w-full rounded-[8px] md:p-[20px] p-[12px] border border-[#EBEDEF] text-[#3C3D3E]">
                                    <h2 className="fw-bold">Order Summary</h2>
                                    <div className="border-b-1 border-gray py-[28px] flex flex-col  gap-[20px]">
                                        <div className="flex justify-between ">
                                            <h2>Subtotal</h2>
                                            <h2 className="fw-bold">
                                                &#8358;{totalPrice}
                                            </h2>
                                        </div>

                                        <div className="flex justify-between">
                                            <h2>Tax</h2>
                                            <span className="fw-bold">
                                                &#8358;{"0"};
                                                {/* {(totalPrice * 0.05).toFixed(2)} */}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex justify-between py-[28px] mb-[42px]">
                                        <h2>Total</h2>
                                        <span className="fw-bold">
                                            &#8358;
                                            {totalPrice.toFixed(2)}
                                        </span>
                                    </div>
                                    <button
                                        className="bg-gradient-to-r from-red to-orange w-full p-[17px]  fw-bold text-white rounded-[12px]"
                                        onClick={handleCheckout}
                                    >
                                        Proceed to Checkout
                                    </button>
                                </div>
                                <Link
                                    to="/allProduct"
                                    className="text-red p-[15px] border fw-bold rounded-[12px] block mt-[20px] text-center border-red "
                                >
                                    Continue Shopping
                                </Link>
                            </div>
                        </div>
                        </>
                    )}
                </div>
            </div>
            <Footer />            
        </div>
    );
};

export default Cart;
