/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// import Image from "../Images/images.jpg";
import { useParams } from "react-router-dom";

const Pending = () => {
    const { id } = useParams();
    const [orderDetails, setOrderDetails] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchOrderDetails = async () => {
        const token = localStorage.getItem("authToken");
        setLoading(true);

        try {
            const response = await fetch(
                `https://getquickshop.online/api/v1/user/buyer/ords/${id}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();
            const orderDetails = responseData.data;

            setOrderDetails(orderDetails);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrderDetails(id);
    }, [id]);

    return (
        <div>
            {loading ? <div>Loading....</div> : ""}
            {error ? <div>Error: {error}</div> : ""}

            {orderDetails ? (
                <div className="flex flex-col md:flex-row bg-white rounded-lg md:h-[30rem]  xl:ml-10 md:w-full mx-4">
                    <div className="bg-white border-1 border-gray pb-[5rem] m-4 rounded-lg md:w-full ">
                        <div className="border-b-1 border-gray flex justify-between p-2">
                            <div>
                                <h2>Order Summary</h2>
                            </div>
                            <div>
                                <button className="bg-BlushBlue text-navy rounded-2xl px-4 py-2 capitalize">
                                    {orderDetails?.attributes?.order_status}
                                </button>
                            </div>
                        </div>
                        <div className="px-2">
                            <h2>
                                Order ID:{id}
                                {orderDetails?.id}
                            </h2>
                        </div>

                        <div className="flex items-center justify-between p-2">
                            <div className="flex">
                                {/* <div>
                                    <img
                                        src={Image}
                                        alt=""
                                        className="h-[5rem] w-24 rounded-lg"
                                    />
                                </div> */}

                                <div className="fw-bold">
                                    <p>
                                        {
                                            orderDetails?.attributes?.items[0]
                                                ?.product_name
                                        }{" "}
                                        {
                                            orderDetails?.attributes?.items[0]
                                                .quantity
                                        }
                                    </p>
                                </div>
                            </div>
                            <div>
                                <h2 className="px-4">
                                    &#8358;{orderDetails?.attributes?.total}
                                </h2>
                            </div>
                        </div>
                        <div className="flex justify-between p-2">
                            <div>
                                <h2>{orderDetails?.attributes?.store_name}</h2>
                            </div>
                            <div>
                                <h2>
                                    {new Date(
                                        orderDetails?.attributes?.created_at
                                    ).toLocaleString()}
                                </h2>
                            </div>
                        </div>
                        <div className="flex justify-between p-2">
                            <div>
                                <h2>Subtotal</h2>
                            </div>
                            <div>
                                <h2>
                                    &#8358;
                                    {orderDetails?.attributes?.subtotal || ""}
                                </h2>
                            </div>
                        </div>
                        <div className="flex justify-between p-2">
                            <div>
                                <h2>Shipping Fee</h2>
                            </div>
                            <div>
                                <h2>
                                    &#8358;
                                    {orderDetails?.attributes?.shipping_fee ||
                                        "0"}
                                </h2>
                            </div>
                        </div>
                        <div className="flex justify-between p-2">
                            <div>
                                <h2>Total</h2>
                            </div>
                            <div>
                                <h2>
                                    &#8358;
                                    {orderDetails?.attributes?.total || ""}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="border-gray border-1 h-fit m-4 bg-white rounded-lg px-4 md:w-full">
                        <div className="border-b-1 border-gray p-2">
                            <h2>Delivery Information</h2>
                        </div>
                        {orderDetails?.attributes?.shipping_method !== null ? (
                            <>
                                <div className="px-2">
                                    <h2>
                                        {orderDetails?.attributes
                                            ?.shipping_information?.name || ""}
                                    </h2>
                                    <h3>
                                        {orderDetails?.attributes
                                            ?.shipping_information
                                            ?.customer_shipping_address ||
                                            "not available"}
                                    </h3>
                                    <h4>
                                        {orderDetails?.attributes
                                            ?.shipping_information
                                            ?.mobile_number || "not available"}
                                    </h4>
                                </div>
                                <div className="flex justify-between px-2">
                                    <div>
                                        <h2>Shipping Method</h2>
                                    </div>
                                    <div>
                                        <h2>
                                            {orderDetails?.attributes
                                                ?.shipping_method || ""}
                                        </h2>
                                    </div>
                                </div>
                                <div className="flex justify-between px-2 pb-[5rem]">
                                    <div>
                                        <h2>Estimated Delivery Date</h2>
                                    </div>
                                    <div>
                                        <h2>
                                            {orderDetails?.attributes
                                                ?.estimated_delivery_date
                                                ? new Date(
                                                      orderDetails?.attributes?.estimated_delivery_date
                                                  ).toLocaleDateString()
                                                : ""}
                                        </h2>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="flex justify-between px-2 pb-[5rem] py-3">
                                <div>
                                    <h2>Pick Up Code</h2>
                                </div>
                                <div>
                                    <h2>
                                        {
                                            orderDetails?.relationship
                                                ?.pickup_code?.status
                                        }
                                    </h2>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ):(
                <div className="flex flex-col md:flex-row bg-white rounded-lg md:h-[30rem]  xl:ml-10 md:w-full mx-4">
                    <p>Invalid Order Details</p>
</div>
            )}
        </div>
    );
};

export default Pending;
