/* eslint-disable react-hooks/exhaustive-deps */
// import ProductHeader from "../ProductHeader/ProductHeader";
import { Link, useLocation } from "react-router-dom";
import { RxSlash } from "react-icons/rx";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../api/apiConstants";
import axios from "axios";

export default function PaymentSuccess() {
    const location = useLocation();
    const [processing, setProcessing] = useState(true);
    const [message, setMessage] = useState("");
    const [tranx, setTranx] = useState({
        tranxId: "",
        txRef: "",
        orderId: ""
    })
    
    
    
        useEffect(() => {
            const queryParams = new URLSearchParams(location.search);

            const transactionId = queryParams.get("transaction_id");
            const txRef = queryParams.get("tx_ref");
            const orderId = localStorage.getItem("order_id");
            setTranx({
                orderId,
                tranxId:transactionId,
                txRef
            })        
            postPaymentDetails();
            return () => postPaymentDetails();
        }, []);

    const postPaymentDetails = async () => {
        const token = localStorage.getItem("authToken");

        const data = {
            order_id: tranx.orderId, // localStorage.getItem("order_id"),
            amount: localStorage.getItem("order_amount"),
            trx_ref: tranx.txRef, //txRef,
            transaction_id: tranx.tranxId, //transactionId,
        };
        try {
            axios.post(`${BASE_URL}/user/buyer/payments`, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
        } catch (error) {
            setMessage("payment cannot be verified");
            console.error("Error posting payment details:", error);
        } finally {
            setProcessing(false);
            localStorage.removeItem("order_id");
            localStorage.removeItem("order_amount");
        }
    };

    return (
        <div>
            <div className="bg-grey h-fit px-[100px] py-[40px]">
                <div className="">
                    <div className="flex">
                        <Link to="/">
                            <h2>Home</h2>
                        </Link>
                        <div className="flex items-center">
                            <span className="inline-block">
                                <RxSlash />
                            </span>
                            <div className="text-red inline-block">
                                <Link to="/Cart">Cart</Link>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <span className="inline-block">
                                <RxSlash />
                            </span>
                            <div className="text-red inline-block">
                                <Link to="/Cart">Checkout</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="m-auto">
                    <div className="bg-white rounded-2xl flex flex-col gap-[60px] p-[40px] items-center justify-center">
                        {processing ? (
                            <>
                                <div className="py-5 flex flex-col justify-center items-center">
                                    <div class="loader">
                                        <span class="bar"></span>
                                        <span class="bar"></span>
                                        <span class="bar"></span>
                                    </div>
                                    <p className="fw-bold p-3">
                                        Veryfying Payment...
                                    </p>
                                </div>
                            </>
                        ) : (
                            <>
                                {message ? (
                                    <p className="lex flex-col gap-[31px] items-center justify-center">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 384 512"
                                            className="text-red"
                                        >
                                            <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                                        </svg>
                                        <span>Uncessful Payment</span>
                                    </p>
                                ) : (
                                    <>
                                        <div className="flex flex-col gap-[31px] items-center justify-center">
                                            <svg
                                                width="80"
                                                height="80"
                                                viewBox="0 0 80 80"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g id="Group 36">
                                                    <circle
                                                        id="Ellipse 40"
                                                        cx="40"
                                                        cy="40"
                                                        r="40"
                                                        fill="#63AE83"
                                                        fillOpacity="0.13"
                                                    />
                                                    <g id="Group 35">
                                                        <circle
                                                            id="Ellipse 41"
                                                            cx="40.0013"
                                                            cy="39.9994"
                                                            r="21.3333"
                                                            fill="#219653"
                                                        />
                                                        <path
                                                            id="Vector"
                                                            d="M38.0631 46.2593C37.7076 46.2593 37.3698 46.1179 37.1209 45.8704L32.0898 40.8672C31.5742 40.3545 31.5742 39.5059 32.0898 38.9932C32.6053 38.4806 33.4587 38.4806 33.9742 38.9932L38.0631 43.0594L47.2009 33.9724C47.7165 33.4597 48.5698 33.4597 49.0854 33.9724C49.6009 34.4851 49.6009 35.3337 49.0854 35.8464L39.0053 45.8704C38.7565 46.1179 38.4187 46.2593 38.0631 46.2593Z"
                                                            fill="white"
                                                        />
                                                    </g>
                                                </g>
                                            </svg>
                                            <div className="flex flex-col gap-[12px] items-center justify-center">
                                                <h1 className="font-bold text-[18px] leading-[24.3px]">
                                                    Payment Successfull
                                                </h1>
                                                <p className="fw-bold text-base leading-[21.6px]">
                                                    Your purchase was
                                                    successful. We have sent a
                                                    copy of your receipt to your
                                                    email address.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex flex-col gap-[16px]">
                                            <Link
                                                to={`order-details/${tranx.orderId}`}
                                                className="btn font-bold text-white text-base leading-[21.6px] bg-gradient-to-r from-[#F90013] to-[#FF4800]"
                                            >
                                                View Order
                                            </Link>
                                            <Link
                                                to="/allProduct"
                                                className="btn font-bold text-base leading-[21.6px] text-[#F90013] bg-white border-[#F90013]"
                                            >
                                                Continue Shopping
                                            </Link>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
